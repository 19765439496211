import React, { useEffect, Suspense, useContext, useState } from "react";
import { Route, Switch, useLocation, Redirect, useHistory } from "react-router-dom";

//Funciones
import { setTSConc, setStatsSession } from "./js/functions/estadisticas.js";
import useObtenerSubsecciones from "./js/translations/useObtenerSubsecciones";
import Hotjar from "@hotjar/browser";

//Store
import { store } from "./components/Store";

//Componentes
import Blog from "./blog";
import { BlogPost } from "./blog/BlogPost/index.js";
import BlogRedirection from "./blog/BlogRedirection";
import Busqueda from "./busqueda/busqueda";
import { Club } from "./club-makemake/index.js";
import Demo from "./demo";
import Descargados from "./offline/descargados";
import Error404 from "./404";
import FichaColeccion from "./coleccion";
import FichaEditorial from "./editorial";
import Ficha from "./fichas/Ficha.js";
import Header from "./components/header/index";
import { MenuResponsive } from "./components/menu/index.js";
import InformacionInstitucional from "./informacion";
import Ayuda from "./ayuda-soporte/index.js";
/* import Login from "./login/login"; */
import { Login } from "./login/index.js";
import MiPanel from "./mi-panel";
import PoliticaPrivacidad from "./politica-de-privacidad";
import PoliticaSoporte from "./PoliticaSoporte";
import QueEsRouter from "./que-es/router";
import ScrollToTop from "./js/utilities/ScrollToTop";
// import SinConexion from './offline/sin-conexion';
import SuscripcionBoletin from "./suscripcion-boletin";
import TerminosCondiciones from "./terminos-y-condiciones";
import Visor from "./visor";
import Institucional from "./institucional/index.js";

import { ModoPWAContext } from "./contexts/ModoPwaContext";
import { useDetectAdBlock } from "adblock-detect-react";
import { useNetworkStatus } from "./hooks/use-network-status.js";
import { useModoAplicacion } from "./hooks/useModoAplicacion.js";
import { UpdateButton } from "./components/UpdateButton.js";
import Footer from "./components/footer/index.js";
import { useQueryParams } from "./hooks/useQueryParams.js";

import { DemoPopUp } from "./demo-popup/demo-popup.js";
// import { useChat } from "./hooks/useChat.js";
import { TiendaMakeMake } from "./tienda-makemake/index.js";
import { Curadurias } from "./curadurias/index.js";
import { AdvancedSearch } from "./components/advanced-search/index.js";
import { Accessibility } from "./components/accesibility/index.js";
import { Home } from "./home/index.js";
import { ExternalAuth } from "./components/external-auth/index.js";
import { InstitutionalAuthRouter } from "./institutional-auth/index.js";
import { ToastContainer } from "react-toastify";
import { Favoritos } from "./favoritos/index.js";
import { EdTechStandard } from "./edtech-standard/index.js";
import { useServiceWorkerManager } from "./hooks/use-sw-manager.js";
import { MobileAppPopup } from "./components/mobile-app-popup.js";
import { SplashScreen } from "./components/splash-screen.js";
import { ResourcesCenter } from "./resources-center/index.js";
import ReactPixel from "react-facebook-pixel";
import { NotificationInbox } from "./notifications/components/inbox.js";
import { NotiBanner } from "./notifications/components/notibanner.js";
import { Events } from "./eventos/index.js";
import { MainPageMetadata } from "./components/seo/main-page-metadata.js";
import { CollectionDevPolicy } from "./collection-dev-policy.js";
import { Landings } from "./landings/index.js";
import { Reader } from "./reader/index.js";
import { AppUpdating } from "./components/app-updating.js";
import { AdminLogin } from "./admin-login/index.js";

//Componentes
/* const Home = lazy(() => import("./home"));
const Login = lazy(() => import("./login"));
const SuscripcionBoletin = lazy(() => import("./suscripcion-boletin"));
const Informacion = lazy(() => import("./informacion"));
const FichaLibro = lazy(() => import("./fichas"));
const FichaColeccion = lazy(() => import("./coleccion"));
const FichaEditorial = lazy(() => import("./editorial"));
const Busqueda = lazy(() => import("./busqueda/busqueda"));
const TerminosCondiciones = lazy(() => import("./terminos-y-condiciones"));
const PoliticaPrivacidad = lazy(() => import("./politica-de-privacidad"));
const Error404 = lazy(() => import("./404"));
const Descargados = lazy(() => import("./offline/descargados"));
const SinConexion = lazy(() => import("./offline/sin-conexion"));
const Visor = lazy(() => import("./visor"));
const InstruccionesDescarga = lazy(() => import("./home/InstruccionesDescarga"));
const Blog = lazy(() => import("./blog"));
const MiPanel = lazy(() => import("./mi-panel")); */

//Componente principal
/**
 * El componente `Application` es el punto de entrada principal de la aplicación. Es responsable de coordinar
 * y gestionar todos los demás componentes y módulos de la aplicación.
 * - Internamente se encarga de realizar lógica relacionada al registro, activación, actualización y gestión del service worker
 * - Contiene el enrutamiento principal de toda la aplicación, por lo tanto se encarga de renderizar el componente correspondiente con base a la ruta actual.
 * - Gestiona eventos y acciones globales de la aplicación.
 * - Gestiona la autenticación y el control de acceso.
 */
const Application = () => {
	const { isReady, textosSubSecciones: textoHome } = useObtenerSubsecciones("home");

	const globalState = useContext(store);
	const { dispatch, state } = globalState;
	const { progresoDescarga } = state;
	const location = useLocation();
	const adBlockDetector = useDetectAdBlock();
	const conexion = useNetworkStatus();
	const modoAplicacion = useModoAplicacion();
	const params = useQueryParams();
	const history = useHistory();
	const authenticatedUser = globalState?.state?.sesionActiva === "SI";
	const serviceWorkerManager = useServiceWorkerManager();
	const [isProxy, setIsProxy] = useState(true);
	const [demoCleverVisible, setDemoCleverVisible] = useState(null);

	// const chat = useChat();

	//identificador de hotjar
	const siteId = 3689730;
	const hotjarVersion = 6;

	//inicializa el script de hotjar
	useEffect(() => {
		Hotjar.init(siteId, hotjarVersion);
		fetch("/makemake-reader/assets/epub-reader-settings.css", {
			"headers": {
			  "accept": "text/css,*/*;q=0.1",
			  "accept-language": "en-US,en;q=0.9,es;q=0.8",
			  "cache-control": "no-cache",
			  "pragma": "no-cache",

			},
			"body": null,
			"method": "GET"
		  });
	}, []);

	// Registro del service worker.
	useEffect(() => {
		serviceWorkerManager.register();
	}, []);

	const handleProxySplashScreen = () => {
		if (
			window.location.origin.split("").reverse().join("") === "oc.moc.ekamekam//:sptth" ||
			window.location.origin === "https://nf.app.makemake.reallabs.com.co" ||
			window.location.origin.includes("localhost") ||
			window.location.origin === ("https://makemake-com-co.comfenalcoantioquia.basesdedatosezproxy.com")
		) {
			setIsProxy(false);
		} else {
			setIsProxy(true);
		}
	};

	useEffect(() => {
		handleProxySplashScreen();
	}, [location]);



	const redirectHandler = () => {
		const redirectQuery = decodeURIComponent(params.get("redirect"));
		if (location.pathname === "/" && params.has("redirect")) {
			history.push(redirectQuery);
		}
	};

	useEffect(() => {
		redirectHandler();
	}, [location, params]);

	useEffect(() => {
		// mostrar el modal de adblock activado
		//OCULTO TEMPORALMENTE - AL
		// let timer = setTimeout(() => {
		//   if (
		//     adBlockDetector === true &&
		//     modoAplicacion === false &&
		//     conexion.online === true
		//   ) {
		//     try {
		//       MicroModal.show("modal-adblock-detector", {
		//         awaitCloseAnimation: true,
		//       });
		//     } catch (error) {
		//       PWAconsole.log(error);
		//     }
		//   }
		// }, 2000);
		// return () => {
		//   clearTimeout(timer);
		// };
	}, [adBlockDetector, conexion.online, modoAplicacion]);

	useEffect(() => {
		//AE: ESTO DEBERÍA ESTAR EN LA FICHA DE LIBRO
		//NZ: se agrega aqui para permitir desscargas mientras se sigue navegando en makemake
		if (progresoDescarga === "100%") {
			//Retrasa la desparicion de la barra de progreso
			setTimeout(() => {
				dispatch({
					type: "CHANGE_VALUE",
					property: "descargando",
					value: false,
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "tituloDescargando",
					value: "",
				});
				dispatch({
					type: "CHANGE_VALUE",
					property: "progresoDescarga",
					value: "0%",
				});
			}, 3000);
		}
	}, [progresoDescarga]);

	useEffect(() => {
		const timerTs = setInterval(setTSConc, 50000);
		const timerStatsSession = setInterval(setStatsSession, 9000);

		return () => {
			clearInterval(timerStatsSession);
			clearInterval(timerTs);
		};
	}, []);

	useEffect(() => {
		try {
			dispatch({
				type: "CHANGE_VALUE",
				property: "abortSignal",
				value: state?.abortCtrl?.signal,
			});
		} catch (error) {
			console.log(error);
		}
	}, [dispatch, state.abortCtrl]);

	// const handleChatVisibility = ()=>{
	//   if (location.pathname.includes('/visor')) {
	//     chat.setVisible(false);
	//     console.log('chat oculto /visor');
	//   } else {
	//     if (authenticatedUser === false) {
	//       chat.setVisible(true);
	//       console.log('chat visible, el usuario no está autenticado y no está en la ruta /visor');
	//     } else {
	//       console.log('chat oculto, el usuario está autenticado y no está en la ruta /visor');
	//       chat.setVisible(false);
	//     }
	//   }
	// }
	// useEffect(() => {
	//   handleChatVisibility();
	// }, [location.pathname, authenticatedUser])

	const skipLink = (event) => {
		event.preventDefault();
		const container = document.querySelector("#main");
		if (container) {
			container.tabIndex = -1;
			container.focus();
			setTimeout(() => container.removeAttribute("tabindex"), 1000);
		}
	};

	useEffect(() => {
		if (location.pathname.includes("/visor")) {
			setDemoCleverVisible(true);
		}
	}, [location.pathname]);

	useEffect(() => {
		const pixelId = "966285465084884";
		const advancedMatching = {};
		const options = {
			autoConfig: true,
			debug: true,
		};
		ReactPixel.init(pixelId, advancedMatching, options);
	}, []);

	useEffect(() => {
		ReactPixel.pageView();
	}, [location.pathname]);

	return (
		<>
			{/* <MainPageMetadata/> */}
			{/* OCULTO TEMPORALMENTE - AL */}
			{/* <ModalAdBlockDetector idModal={"modal-adblock-detector"} /> */}
			{location.pathname.startsWith("/external-auth") ? (
				<Switch>
					<Route path="/external-auth" component={ExternalAuth} />
				</Switch>
			) : (
				<>
					<ScrollToTop>
						<Suspense
							fallback={
								<div className="row mx-0 relative" style={{ minHeight: "100vh" }}>
									<div className={`statistics-preloader`} />
								</div>
							}
						>
							{state.mainDataLoaded === true && (
								<button onClick={(e) => skipLink(e)} className="skip-link w-100">
									{textoHome?.skip?.texto || "Saltar al contenido"}
								</button>
							)}
							<Header />
							<Accessibility />
							<main
								role={"main"}
								id="main"
								tabIndex={0}
								className="focusable-secondary"
								/* style={{ minHeight: "90vh" }} */
							>
								{!location.pathname.includes("visor") && <NotiBanner />}
								<NotificationInbox />
								<AdvancedSearch />
								<Switch>
									<Route exact path="/" component={MainRoutes} />
									<Route exact path="/:page" component={MainRoutes} />
									<Route exact path="/:page/:section" component={MainRoutes} />
									<Route
										exact
										path="/:page/:section/:subsection"
										component={MainRoutes}
									/>
									<Route
										exact
										path="/:page/:section/:subsection/:param"
										component={MainRoutes}
									/>
								</Switch>
							</main>
							{!location.pathname.includes("visor") && <Footer />}
							<MenuResponsive />
						</Suspense>
						{globalState?.state?.username === "Demo_Clever" && (
							<DemoPopUp
								visible={demoCleverVisible}
								setVisible={setDemoCleverVisible}
							/>
						)}
						<AppUpdating/>
						{!location?.pathname?.includes("/visor") && <MobileAppPopup />}
						{isProxy && <SplashScreen />}
					</ScrollToTop>
				</>
			)}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				className={"safe-area"}
				theme="colored"
			/>
		</>
	);
};

/**
 * Contiene todas las rutas principales de la aplicación, es llamado dentro de el componente principal `Application`
 * @returns JSX.Element
 */
const MainRoutes = () => {
	const globalState = useContext(store);
	const { state } = globalState;
	const { mainDataLoaded } = state;
	const pwaState = useContext(ModoPWAContext);
	const isAuth = globalState?.state?.sesionActiva === "SI";
	return (
		<>
			{mainDataLoaded && (
				<Switch>
					<Route exact path="/">
						{isAuth ? <Redirect to="/home" /> : <Redirect to="/que-es" />}
					</Route>
					<Route exact path="/home" component={Home} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/admin/login" component={AdminLogin} />
					<Route path="/institutional-auth" component={InstitutionalAuthRouter} />

					<Route exact path="/busqueda" component={Busqueda} />

					<Route path="/que-es" component={QueEsRouter} />

					<Route exact path="/club-makemake" component={Club} />
					<Route exact path="/centro-de-recursos" component={ResourcesCenter} />
					<Redirect exact from="/club" to={"/club-makemake"} />

					{/* <Route exact path='/codigo' component={Codigo} />
          <Route exact path='/codigo/:section' component={Codigo} /> */}

					{(state.sesionActiva !== "SI" ||
						(state.sesionActiva === "SI" && state.cantidadLibros > 40)) && (
						<Route path="/curadurias" component={Curadurias} />
					)}

					<Route exact path="/blog" component={Blog} />
					<Route exact path="/blog/:id" component={BlogPost} />
					<Route exact path="/blog/:id/:title" component={BlogPost} />

					<Route exact path="/blog/blogDB/:date/:title" component={BlogRedirection} />

					<Route path="/tienda" component={TiendaMakeMake} />
					<Redirect exact from="/quiero-makemake/" to={"/tienda"} />
					<Redirect exact from="/compra/*" to={"/tienda"} />

					<Route exact path="/boletin" component={SuscripcionBoletin} />
					<Route exact path="/boletin/:section" component={SuscripcionBoletin} />

					<Route exact path="/demo" component={Demo} />
					<Route exact path="/demo/:section" component={Demo} />

					<Route exact path="/informacion/" component={InformacionInstitucional} />
					<Route
						exact
						path="/informacion/:section"
						component={InformacionInstitucional}
					/>

					<Route exact path="/coleccion/:nombreColeccion/" component={FichaColeccion} />
					<Route exact path="/editorial/:nombreEditorial/" component={FichaEditorial} />

					<Route exact path="/fichas/:idLibro/:nombreLibro" component={Ficha} />

					<Route exact path="/mi-panel/" component={MiPanel} />
					<Route exact path="/mi-panel/:section/" component={MiPanel} />
					<Route exact path="/mi-panel/:section/:subsection" component={MiPanel} />
					<Route exact path="/mi-panel/:section/:subsection/:param" component={MiPanel} />

					<Route exact path="/politica-de-privacidad" component={PoliticaPrivacidad} />
					<Route exact path="/politica-de-soporte" component={PoliticaSoporte} />
					<Route exact path="/terminos-y-condiciones" component={TerminosCondiciones} />
					<Route exact path="/edtech-open-standard" component={EdTechStandard} />
					<Route
						exact
						path="/collection-development-policy"
						component={CollectionDevPolicy}
					/>
					<Route exact path="/descargados" component={Descargados} />
					{/* <Route exact path='/sin-conexion' component={SinConexion} /> */}
					<Route exact path="/ayuda" component={Ayuda} />
					<Route exact path="/visor/:idLibro" component={Reader} />

					<Route exact path="/eventos/formularios/:idForm" component={Events} />

					{/* <Route exact path="/assets/saml/endpoints/">
            <AssetsRedirect />
          </Route>

          <Route exact path="/assetsPlanLector/saml/endpoints/">
            <AssetsRedirect />
          </Route> */}

					<Route exact path="/institucional/:section/" component={Institucional} />
					<Route path="/external-auth" component={ExternalAuth} />

					<Route path="/favoritos" component={Favoritos} />
					<Route exact path={"/pautas/special-hispanic-heritage-offer-2024"}>
						<Landings.SpecialHispanic />
					</Route>
					<Route
						exact
						path={"/proyecto-piloto-rnbp"}
					>
						<Landings.PilotoRNBP />
					</Route>
					<Route component={Error404} />
				</Switch>
			)}
		</>
	);
};

export default Application;
